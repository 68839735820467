/* custom-select.css */

/* Eliminar el borde del selector */
.ant-select-selector {
    border: none !important;
    box-shadow: none !important;
  }
  
  /* Estilos personalizados adicionales */
  .ant-select {
    width: 100% !important;
    border-radius: 5px !important;
    border: 2px solid #d7dbdd !important;
    font-family: "Roboto Slab" !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    padding: 0px !important;
    color: #0b3a5a !important;
  }
  
  .ant-select-selection-item {
    color: #0b3a5a !important;
  }
  
  .ant-select-selection-placeholder {
    color: #0b3a5a !important;
  }
  