.table-ocuta {
  display: none;
}
.center-text {
  text-align: center;
}
.button-styles-detalais-reporte {
  background-color: #41b6e6;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  border-width: 0;
  box-shadow: rgba(25, 25, 25, 0.04) 0 0 1px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
  transition: all 200ms;
  font-family: "Roboto Slab";
  border-radius: 50px;
  padding: 6px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
}

.button-styles-consulta {
  background-color: #41b6e6;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  border-width: 0;
  box-shadow: rgba(25, 25, 25, 0.04) 0 0 1px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
  transition: all 200ms;
  font-size: 14px;
  font-family: "Roboto Slab";
  border-radius: 5px;
  padding: 10px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
}
.td-button-styles-detalais-reporte {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.box-table-contantent-reportes {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.container-body-reclasificacion-table {
  width: 90%;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.container-body-notificaciones-table {
  background-color: #fff;
  border-radius: 10px;
  padding: 40px;
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 30px;
  margin-right: 30px;
  text-align: start;
}
.button-send-notificaciones{
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-reporte-consulta-box-reporte {
  margin: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  align-items: center;
  align-content: center;
  padding: 10px;
}

@media only screen and (max-width: 1000px) {
  .top-reporte-consulta-box-reporte {
    margin: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    align-items: center;
    align-content: center;
    padding: 10px;
  }
  .select-container {
    margin: 10px;
    position: relative;
    display: inline-block;
    font-size: 12px;
    font-family: "Roboto Slab";
  }
  .picker-date-reporte {
    margin: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    appearance: none; /* Elimina los estilos nativos del sistema operativo */
  }
  .button-styles-consulta {
    margin: 10px;
    background-color: #41b6e6;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    border-width: 0;
    box-shadow: rgba(25, 25, 25, 0.04) 0 0 1px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
    transition: all 200ms;
    font-size: 14px;
    font-family: "Roboto Slab";
    border-radius: 5px;
    padding: 10px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
  }
}

.pagination-fooder {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.pagination-data {
  margin-top: 10px;
  margin-bottom: 10px;
}

/*select*/
.picker-date-reporte {
  display: flex;
  flex-direction: row;
  align-items: center;
  appearance: none; /* Elimina los estilos nativos del sistema operativo */
}

.react-datepicker {
  font-family: Arial, sans-serif;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  border-width: 0;
  box-shadow: rgba(25, 25, 25, 0.04) 0 0 1px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
  transition: all 200ms;
  appearance: none; /* Elimina los estilos nativos del sistema operativo */
}

.react-datepicker__input-container {
  display: inline-block;
}

.react-datepicker__input-container input {
  height: 40px;
  width: 120px;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  border-width: 4;
  box-shadow: rgba(25, 25, 25, 0.04) 0 0 1px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
  transition: all 200ms;
  appearance: none; /* Elimina los estilos nativos del sistema operativo */
}

.react-datepicker__month-container {
  margin-top: 10px;
}

.react-datepicker__day {
  color: #333;
}

.react-datepicker__day--selected {
  background-color: #007bff;
  color: #fff;
}

.react-datepicker__day--disabled {
  color: #ccc;
}
.react-datepicker__input-container .react-datepicker-wrapper {
  position: relative;
}

.react-datepicker__input-container .react-datepicker-wrapper::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 20px;
  height: 40px;
  border-width: 0;
  box-shadow: rgba(25, 25, 25, 0.04) 0 0 1px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
  transition: all 200ms;
  appearance: none; /* Elimina los estilos nativos del sistema operativo */
}

/*Table*/

.table-reporte-cierre-dia {
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  margin-bottom: 20px;
}
.container-table-reporte {
  overflow-x: scroll;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.body {
  min-height: 100vh;
  margin: 0;
  background-color: #e2ecf1;
}

@media (min-width: 36em) {
  .table {
    width: 100%;
    margin: auto;
  }
}

.caption {
  font-size: 8px;
}

.thead {
  border-radius: 5px;
  width: 100%;
}
@media (min-width: 36em) {
  .thead {
    display: table-header-group;
  }
}

.tr {
  text-align: center;
  margin: 2px 0;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
@media (min-width: 36em) {
  .tr {
    display: table-row;
    margin: 0;
    border-radius: initial;
    box-shadow: none;
    text-align: center;
  }
}

.th-reporte-table {
  box-sizing: border-box;
  position: sticky;
  top: 0;
  padding: 6px 10px;
  background-color: #41b6e6;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-family: "Roboto Slab";
  font-weight: bold;
}

th:not([class]) {
  text-align: center;
  font-size: 12px;
  font-family: "Roboto Slab";
  font-weight: bold;
  padding: 6px 10px;
}

@media (min-width: 36em) {
  .td {
    border-bottom: 1px solid silver;
    border-top: 1px solid silver;
  }
  .tr:last-child > td {
    border-bottom: 0;
  }
}

td:not([class]) {
  padding: 6px 10px;
  font-size: 12px;
  font-family: "Roboto Slab";
}
@media (min-width: 36em) {
  .td:not([class]) {
    display: table-cell;
    padding: 4px 8px;
  }
}

.td[data-label]::before {
  content: attr(data-label);
  margin-right: auto;
  color: rgba(0, 0, 0, 0.6);
}
@media (min-width: 36em) {
  .td[data-label]::before {
    content: none;
  }
}

.car-name {
  align-items: center;
  padding: 4px;
  font-size: 8px;
}
@media (min-width: 36em) {
  .car-name {
    display: table-cell;
    padding: 4px 8px;
  }
}

.car-name > img {
  object-fit: contain;

  margin-right: 0px;
}
@media (min-width: 36em) {
  .car-name > img {
    display: block;
    margin-right: 0;
  }
}

.container {
  max-width: 45rem;
  margin: 0 8px;
  padding-bottom: 100vh;
}
@media (min-width: 36em) {
  .container {
    margin: auto;
  }
}

.container-table-reporte {
  width: 100%;
}

/*Select*/
/* Estilo para el contenedor del select */
.select-container {
  position: relative;
  display: inline-block;
  font-size: 12px;
  font-family: "Roboto Slab";
}

/* Estilo para el select */
.select-container select {
  width: 250px;
  height: 40px;
  padding: 6px;
  font-size: 12px;
  font-family: "Roboto Slab";
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  appearance: none; /* Elimina los estilos nativos del sistema operativo */
  border-width: 2;
  box-shadow: rgba(25, 25, 25, 0.04) 0 0 1px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
  transition: all 200ms;
}

/* Estilo para la flecha del select */
.select-container::after {
  content: "\25BE"; /* Código Unicode para la flecha hacia abajo */
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

/* Estilo para el hover del select */
.select-container select:hover {
  border-color: #999;
}

/* Estilo para el focus del select */
.select-container select:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

/* Estilo para las opciones del select */
.select-container select option {
  background-color: #fff;
  font-size: 12px;
  font-family: "Roboto Slab";
}

/*Modal detailes*/

.container-box-modal {
  font-family: "Roboto Slab";
  font-size: 14px;
  width: 100%;
}
.box-images-report-modal {
  margin-top: 5px;
  width: 50%;
}
.box-images-report-modal-detailes {
  display: flex;
  flex-direction: column;
}
.box-images-report-modal-detailes-item {
  font-size: 12px;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.image-reporte-detailes {
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}
.item-formapago-reporte {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.text-fomaspago-reporte {
  font-size: 10px;
  font-family: "Roboto Slab";
  font-weight: 500;
}
.text-fomaspago-reporte-descrip {
  font-size: 10px;
  font-family: "Roboto Slab";
  font-weight: 500;
}
.info-formas-pago-content-reporte {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .info-formas-pago-content-reporte {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
  }
}

.info-date-register-reporte {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.row-formas {
  width: 50%;
  margin-left: 10px;
  margin-right: 10px;
}
.row-location {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  justify-content: space-between;
}
.row-fecha-reporte {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
}
.row-location-item {
  width: 50%;
  justify-content: space-between;
}
.text-fomaspago-reporte-info {
  font-size: 10px;
  font-family: "Roboto Slab";
  font-weight: 500;
  margin-bottom: 5px;
}

.text-formaspago-reporte-info-comentarios {
  width: 100%;
  font-size: 10px;
  font-family: "Roboto Slab";
  font-weight: 500;
  display: flex;
}

.box-formaspago-reporte-info-comentarios {
  width: 100%;
  display: block;
}

.comment-container {
  width: 100%;
  font: 500 10px "Roboto Slab";
  display: flex;
  flex-wrap: wrap; /* Asegura que el texto se envuelva en múltiples líneas si es necesario */
  overflow: auto;
}

.Title-report-modal {
  margin-left: 5px;
  display: flex;
  flex-direction: row;
}
.title-item-modal-reporte {
  font-family: "Roboto Slab";
  font-size: 14px;
  margin-right: 5px;
  font-weight: 600;
  color: #0b3a5a
}
.font-text-bold-flags-tag {
  font-size: 12px;
  font-family: "Roboto Slab";
  font-weight: 600;
  color: #0b3a5a;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  
}
.font-text-bold {
  font-size: 10px;
  font-family: "Roboto Slab";
  font-weight: 600;
  color: #0b3a5a
}
.font-text-boldGreen {
  font-size: 10px;
  font-family: "Roboto Slab";
  font-weight: 600;
  color: #27AE60;
  border: 1px solid #27AE60;
  padding: 1px;
}
.font-text-boldRed {
  font-size: 10px;
  font-family: "Roboto Slab";
  font-weight: 600;
  color: #C0392B;
  border: 1px solid #C0392B;
  padding: 1px;
}
.font-text-bold-title-evidence {
  font-size: 14px;
  font-family: "Roboto Slab";
  font-weight: 600;
  color: #0b3a5a;
}
.zoom-img {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.customModal {
  max-width: 400px;
  width: 100%;
  height: 500px;
}

.image-evidence-reporte{
  margin: 10px;
}

.title-marcar-capilla{
  font-size: 14px;
  font-family: "Roboto Slab";
  font-weight: 700;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 15px;
  align-content: center;
 
}

.row-seccion-detail-reporte{
  flex-direction: row;
  display: flex;
  width: 100%;
}
.box-row-status-details-checkbox{
  display: flex;
  width: 90%;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px;
}
.box-row-status-details-checkbox-informacion{
  margin-left: 8px;
}

@media only screen and (max-width: 600px) {
  .box-row-status-details-checkbox{
    display: flex;
    width: 90%;
    flex-direction: column;
    justify-content: space-between;
    margin: 5px;
  }
  .row-seccion-detail-reporte{
    flex-direction: column;
    display: flex;
    width: 100%;
  }
  .row-formas {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
  .box-images-report-modal {
    margin-top: 5px;
    width: 100%;
  }
}
.center-selecionar-image-valores{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.font-text-boldBlue {
  font-size: 10px;
  font-family: "Roboto Slab";
  font-weight: 600;
  color: #0b3a5a;
  border: 1px solid #0b3a5a;
  padding: 1px;
  }

  .error-input-data{
    font-size: 10px;
    color: #C0392B;
    font-family: "Roboto Slab ";
    font-weight: bold;
  }