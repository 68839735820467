.container-body-horarios {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: auto;
  padding: 0px;
  width: 90%;
  height: 100%;
  margin-top: 20px;
}
.row-body-horarios-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 20px;
  width: 90%;
  border: 2px solid #41b6e6;
  border-radius: 5px;
  align-content: center;
  padding: 0px;
}
.row-body-horarios-left {
  display: flex;
  align-content: center;
  flex-direction: column;
  margin: 20px;
  width: 90%;
  border: 0px solid #41b6e6;
  border-radius: 5px;
  align-content: center;
  padding: 0px;
}
.info-body-horarios {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
}
.text-info-horarios {
  font-size: 16px;
  font-weight: bold;
  color: #0b3a5a;
  font-family: "Roboto Slab";
  text-align: start;
}
.text-data-horarios {
  font-size: 16px;
  font-weight: 100;
  font-family: "Roboto Slab";
  text-align: start;
  border: 1px solid #bfc9ca;
  padding: 5px;
}
.text-data-horarios-weeks {
  font-size: 16px;
  font-weight: 100;
  font-family: "Roboto Slab";
  text-align: start;
  border: 1px solid #bfc9ca;
  padding: 0px;
}
.item-content-list-horarios {
  display: flex;
  flex-direction: row;
  text-align: start;
  width: 100%;
}
.item-name-list-data {
  width: 50%;
  padding: 5;
  margin: 10px;
}
.item-list-horarios {
  display: flex;
  width: 50%;
  padding: 5;
  flex-direction: row;
  justify-content: space-around;
  margin: 10px;
}
.item-name-list {
  font-size: 14px;
  color: #0b3a5a;
  font-weight: bold;
}
.item-puesto-list {
  font-size: 14px;
  color: #41b6e6;
}
.item-hora-title {
  font-size: 14px;
  color: #41b6e6;
  font-weight: bold;
}
.item-number-hora {
  font-size: 14px;
  text-align: center;
}
.item-number-text {
  font-size: 14px;
  text-align: center;
  color: #41b6e6;
  font-weight: bold;
}
.divider {
  border: 1px solid #bfc9ca;
}
.top-list-horarios {
  display: flex;
  font-size: 16px;
  flex-direction: row;
  background-color: #0b3a5a;
  color: #fff;
  justify-content: space-between;
  padding: 10px;
}
.content-box-filter {
  display: flex;
  background-color: #0b3a5a;
  color: #fff;
  width: 100%;
  margin-top: 5px;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  padding-bottom: 10px;
}
.content-filter {
  justify-content: flex-end;
  align-content: end;
  align-items: flex-end;
  width: 50%;
}
.item-hora-row {
  width: 50%;
  text-align: center;
}
.input-buscador-horarios {
  background-color: #0b3a5a;
  border: 1px solid gray;
  padding: 2px;
  border-radius: 4px;
  font-size: 16px;
  color: #fff;
  justify-content: flex-end;
  align-content: flex-end;
  align-items: flex-end;
  width: 50%;
}
.icon-filter-horarios {
  margin: 2px;
}
.button-days {
  color: #41b6e6;
  background-color: #fff;
  cursor: pointer;
  font-family: "Roboto Slab";
  font-size: 18px;
  border: 1px solid #fff;
  font-weight: bold;
  width: 15%;
  height: 30px;
}
.button-days-active {
  color: #fff;
  background-color: #41b6e6;
  cursor: pointer;
  font-family: "Roboto Slab";
  font-size: 18px;
  border: 1px solid #fff;
  font-weight: bold;
  width: 15%;
  height: 30px;
}
.button-clean-horarios {
  background-color: #0b3a5a;
  cursor: pointer;
  font-family: "Roboto Slab";
  font-size: 18px;
  font-weight: bold;
  border: none;
  margin: 2px;
  color: #fff;
}
.button-select-semana-horarios {
  color: #fff;
  background-color: #41b6e6;
  border: none;
}
.content-button-days {
  justify-content: center;
  display: flex;
  width: 100%;
  margin-top: 20px;
  height: 35px;
}
.content-contraler-info {
  margin: 20px;
  padding: 20px;
}
.no-data-onlits {
  margin: 20px;
  font-family: "Roboto Slab";
  font-size: 18px;
  font-weight: bold;
  color: #0b3a5a;
}
.icon-text-top-list-horarios {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.image-top-horarios {
  width: 35px;
  height: 35px;
}
.top-horarios-text {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.button-felchas-select-semana {
  width: 100%;
  color: #fff;
  background-color: #41b6e6;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  display: flex;
  flex-direction: row;
}
.text-semana-item-button-select {
  display: flex;
  margin-left: 10%;
  margin-right: 10%;
}
.diverder-row-week {
  margin-left: 10px;
  margin-right: 10px;
}
@media only screen and (max-width: 590px) {
  .container-body-horarios {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    justify-content: center;
    margin: auto;
    padding: 0px;
    width: 90%;
    height: 100%;
    margin-top: 20px;
  }
  .row-body-horarios-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 20px;
    width: 90%;
    border: 2px solid #41b6e6;
    border-radius: 5px;
    align-content: center;
    padding: 0px;
  }
  .row-body-horarios-left {
    display: flex;
    align-content: center;
    flex-direction: column;
    margin: 20px;
    width: 90%;
    border: 0px solid #41b6e6;
    border-radius: 5px;
    align-content: center;
    padding: 0px;
  }
  .icon-text-top-list-horarios {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .item-list-horarios {
    display: flex;
    width: 50%;
    padding: 5;
    flex-direction: row;
    justify-content: space-around;
    margin: 10px;
  }
  .content-button-days {
    justify-content: center;
    display: flex;
    width: 100%;
    margin-top: 20px;
    height: 35px;
  }
  .button-days {
    color: #41b6e6;
    background-color: #fff;
    cursor: pointer;
    font-family: "Roboto Slab";
    font-size: 12px;
    font-weight: bold;
    border: 1px solid #fff;
    width: 15%;
  }
  .button-days-active {
    color: #fff;
    background-color: #41b6e6;
    cursor: pointer;
    font-family: "Roboto Slab";
    font-size: 12px;
    border: 1px solid #fff;
    font-weight: bold;
    width: 15%;
  }
  .input-buscador-horarios {
    background-color: #0b3a5a;
    border: 1px solid gray;
    padding: 2px;
    border-radius: 4px;
    font-size: 8px;
    color: #fff;
    justify-content: flex-end;
    align-content: flex-end;
    align-items: flex-end;
    width: 50%;
    height: 60%;
  }
}

@media only screen and (max-width: 330px) {
  .button-days {
    color: #41b6e6;
    background-color: #fff;
    cursor: pointer;
    font-family: "Roboto Slab";
    font-size: 8px;
    font-weight: bold;
    border: 1px solid #fff;
    width: 15%;
  }
  .button-days-active {
    color: #fff;
    background-color: #41b6e6;
    cursor: pointer;
    font-family: "Roboto Slab";
    font-size: 8px;
    border: 1px solid #fff;
    font-weight: bold;
    width: 15%;
  }
}
