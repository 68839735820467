.rowcounter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.title-name-admin {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  color: #85929e;
  font-size: calc(10px + 2vmin);
  padding-bottom: 10px;
  font-family: "Roboto Slab";
  padding-top: 10px;
  border-bottom: 1px solid #bfc9ca;
}
.body-color {
  background-color: #e5e8e8;
}
.container-body-admin {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 100%;
  margin-top: 20px;
  border-width: 1px solid #fff;
  border-color: #fff;
}
.admin-box-icons {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.box-admin {
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 5px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

.icon-box-admin {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.icon-item-box-admin {
  width: 10%;
  margin: 30px;
}
.img-admin {
  width: 100%;
}

a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
.icon-goback-title-admin {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
}
.container-item-admin-item-banner {
  display: flex;
  background-color: #d7dbdd;
  border-radius: 10px;
  width: 40%;
  height: 160px;
  margin: 10px;
  font-size: 14px;
  padding: 20px;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Roboto Slab";
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0 3px 4px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
}
.container-item-admin-item-comunicados {
  display: flex;
  background-color: #d7dbdd;
  border-radius: 10px;
  width: 20%;
  height: 160px;
  margin: 10px;
  font-size: 14px;
  padding: 20px;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Roboto Slab";
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0 3px 4px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
}
.container-item-admin-item-marcas {
  display: flex;
  background-color: #d7dbdd;
  border-radius: 10px;
  width: 15%;
  height: 160px;
  margin: 10px;
  font-size: 14px;
  padding: 20px;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Roboto Slab";
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0 3px 4px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
}
.container-item-admin-item-sucursal {
  display: flex;
  background-color: #d7dbdd;
  border-radius: 10px;
  width: 15%;
  height: 160px;
  margin: 10px;
  font-size: 14px;
  padding: 20px;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Roboto Slab";
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0 3px 4px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
}
.container-item-admin-item-rol {
  display: flex;
  background-color: #d7dbdd;
  border-radius: 10px;
  width: 25%;
  height: 160px;
  margin: 10px;
  font-size: 14px;
  padding: 20px;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Roboto Slab";
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0 3px 4px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
}
.image-item {
  width: 50%;
  height: 50%;
}
.item-image-row {
  width: 50%;
}
.item-info-row {
  width: 50%;
}
.item-info-comuicados {
  display: flex;
  justify-content: space-between;
  margin: 5px;
  font-family: "Roboto Slab";
}
.button-agregar {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  margin-bottom: 10px;
}
.button-styles {
  background-color: #41b6e6;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  border-width: 0;
  box-shadow: rgba(25, 25, 25, 0.04) 0 0 1px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
  transition: all 200ms;
  font-size: 14px;
  font-family: "Roboto Slab";
  border-radius: 5px;
  padding: 10px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
}

.button-styles-editar {
  background-color: #41b6e6;
  color: #fff;
  cursor: pointer;
  border-width: 0;
  box-shadow: rgba(25, 25, 25, 0.04) 0 0 1px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
  transition: all 200ms;
  font-size: 14px;
  font-family: "Roboto Slab";
  border-radius: 5px;
  padding: 10px;
  font-weight: bold;
}

/*//// modal///////////*/
.modal {
  font-family: "Roboto Slab";
  font-weight: bold;
  color: #282c34;
}

.modal {
  position: fixed;
  width: 400px;
  z-index: 1040;
  top: 10%;
  left: 35%;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.backdrop {
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
}

.modal-title {
  font-weight: bold;
  font-size: 1.25rem;
  color: #282c34;
}

.modal-header {
  border-bottom: 1px solid #e9ecef;
  display: flex;
  justify-content: center;
}

.modal-desc,
.modal-header,
.modal-footer {
  padding: 12px;
  justify-content: center;
}

.close-button {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  border: none;
  background-color: white;
  opacity: 0.7;
  cursor: pointer;
}
.button-modal {
  cursor: pointer;
  font-size: 1rem;
  padding: 10px 12px;
  border-radius: 5px;
  border: none;
  width: 30%;
  margin-top: 10px;
}

.modal-footer-edith {
  border-top: 2px solid #e9ecef;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  align-content: center;
  margin-top: 5px;
}

.secondary-button {
  color: #fff;
  background-color: #6c757d;
  font-family: "Roboto Slab";
}
.primary-button {
  color: #fff;
  background-color: #41b6e6;
  font-family: "Roboto Slab";
}
.primary-button-disable {
  color: #fff;
  background-color: #899194;
  font-family: "Roboto Slab";
}

.modal-example {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #282c34;
}
.input-content-titulo {
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: bold;
  font-family: "Roboto Slab";
  border: none;
  color: #0b3a5a;
  padding: 5px;
}

/*input form*/
.input-content {
  width: 100%;
  border-radius: 5px;
  border: 2px solid #d7dbdd;
  font-family: "Roboto Slab";
  font-size: 14px;
  font-weight: 400;
  padding: 5px;
  color: #0b3a5a;
}
.input-content-report {
  width: 100%;
  margin-bottom: 5px;
  border-radius: 5px;
  border: 2px solid #d7dbdd;
  font-family: "Roboto Slab";
  font-size: 14px;
  font-weight: 400;
  padding: 5px;
  color: #0b3a5a;
}

.input-content-report-evidencia {
  width: 100%;
  margin-bottom: 5px;
  border-radius: 5px;
  border: 2px solid #d7dbdd;
  font-family: "Roboto Slab";
  font-size: 10px;
  font-weight: 400;
  padding: 5px;
  color: #0b3a5a;
}

.input-content-select {
  width: 100%;
  margin-bottom: 5px;
  border-radius: 5px;
  border: 2px solid #d7dbdd;
  font-family: "Roboto Slab";
  font-weight: 400;
  padding: 5px;
  color: #0b3a5a;
  font-family: "Roboto Slab";
  font-size: 14px;
  height: 45px;
}

.text-context-inputs {
  margin-bottom: 2px;
  border: none;
  font-family: "Roboto Slab";
  font-size: 14px;
  font-weight: bold;
  color: #0b3a5a;
}
.text-context-snap {
  border: none;
  font-family: "Roboto Slab";
  font-size: 8px;
  font-weight: 400;
  color: #0b3a5a;
}
.input-content-img {
  width: 50%;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.row-image-banner {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #d7dbdd;
  font-family: "Roboto Slab";
  font-size: 14px;
  font-weight: 400;
  padding: 5px;
  color: #0b3a5a;
}
.image-content {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  align-content: center;
}

/*File selected*/

.file-select {
  position: relative;
  display: inline-block;
}

.file-select::before {
  background-color: #41b6e6;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  content: "Seleccionar"; /* testo por defecto */
  position: absolute;
  font-family: "Roboto Slab";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.file-select input[type="file"] {
  opacity: 0;
  width: 200px;
  height: 32px;
  font-family: "Roboto Slab";
  display: inline-block;
}

#src-file1::before {
  content: "Seleccionar imagen";
}

@media only screen and (max-width: 600px) {
  .row-image-banner {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #d7dbdd;
    font-family: "Roboto Slab";
    font-size: 14px;
    font-weight: 400;
    padding: 5px;
    color: #0b3a5a;
  }
}


.button-modal-imagen {
  cursor: pointer;
  padding: 5px 5px;
  border-radius: 5px;
  border: none;
  font-size: 12px;
  font-weight: 700;
  }

  .primary-button-cancel {
    color: #fff;
    background-color: #cf3b3b;
    font-family: "Roboto Slab";
    margin-left: 2px;
    margin-right: 2px;
  }
  .primary-button-disable {
    color: #fff;
    background-color: #899194;
    font-family: "Roboto Slab";
    margin-left: 2px;
    margin-right: 2px;
  }