.body {
  min-height: 100vh;
  margin: 0;
  background-color: #e2ecf1;
}

.image-item-banner {
  width: 30%;
  height: 30%;
}

.image-item-table-item {
  width: 50px;
  height: 50px;
}

.table {
  border-collapse: collapse;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 20px;
  margin: 0 0 1em 0;
}
.top-admin-consulta-box-catalogo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  align-content: center;
  padding: 10px;
}
.td-button-styles-detalais-catalogo {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 5px;
}
.button-styles-detalais-catalogo {
  background-color: #41b6e6;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  border-width: 0;
  box-shadow: rgba(25, 25, 25, 0.04) 0 0 1px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
  transition: all 200ms;
  font-family: "Roboto Slab";
  border-radius: 50px;
  padding: 6px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
}
.container-body-catalogo-table {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.table-catalogo {
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  margin-bottom: 20px;
}

/*Table*/

.table-reporte-catalogo {
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  margin-bottom: 20px;
}
.container-table-catalogo {
  overflow-x: scroll;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.body {
  min-height: 100vh;
  margin: 0;
  background-color: #e2ecf1;
}

@media (min-width: 36em) {
  .table {
    width: 100%;
    margin: auto;
  }
}

.caption {
  font-size: 8px;
}

.thead {
  border-radius: 5px;
  width: 100%;
}
@media (min-width: 36em) {
  .thead {
    display: table-header-group;
  }
}

.tr {
  text-align: center;
  margin: 2px 0;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
@media (min-width: 36em) {
  .tr {
    display: table-row;
    margin: 0;
    border-radius: initial;
    box-shadow: none;
    text-align: center;
  }
}

.th-catalogo-table {
  position: sticky;
  top: 0;
  padding: 8px 10px;
  background-color: #41b6e6;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-family: "Roboto Slab";
  font-weight: bold;
}

.th-catalogo-table-data-id {
  position: sticky;
  top: 0;
  padding: 2px 2px;
  text-align: center;
  color: #0b3a5a;
  font-size: 12px;
  font-family: "Roboto Slab";
  font-weight: bold;
  background-color: #fff;
}
.th-catalogo-table-data {
  position: sticky;
  top: 0;
  padding: 2px 2px;
  text-align: center;
  font-size: 12px;
  font-family: "Roboto Slab";
  font-weight: 500;
  background-color: #fff;
}
.th-catalogo-table-data-stack {
  position: sticky;
  top: 0;
  padding: 2px 2px;
  text-align: center;
  font-size: 12px;
  font-family: "Roboto Slab";
  font-weight: 500;
  background-color: #fff;
  /* Propiedades adicionales para manejar textos largos */
  word-wrap: break-word; /* Permitir saltos de línea en palabras largas */
  max-width: 180px; /* Ancho máximo del contenedor */
  min-width: 180px;
}
.th-catalogo-table-data-acceso {
  position: sticky;
  top: 0;
  padding: 2px 2px;
  text-align: center;
  font-size: 12px;
  font-family: "Roboto Slab";
  font-weight: 500;
  background-color: #fff;
  /* Propiedades adicionales para manejar textos largos */
  word-wrap: break-word; /* Permitir saltos de línea en palabras largas */
  max-width: 90px; /* Ancho máximo del contenedor */
  min-width: 90px;
}

th:not([class]) {
  text-align: center;
  font-size: 12px;
  font-family: "Roboto Slab";
  font-weight: bold;
  padding: 6px 10px;
}

@media (min-width: 36em) {
  .td {
    border-bottom: 0px solid silver;
    border-top: 0px solid silver;
  }
  .tr:last-child > td {
    border-bottom: 0;
  }
}

td:not([class]) {
  padding: 6px 10px;
  font-size: 12px;
  font-family: "Roboto Slab";
}
@media (min-width: 36em) {
  .td:not([class]) {
    display: table-cell;
    padding: 4px 8px;
  }
}

.td[data-label]::before {
  content: attr(data-label);
  margin-right: auto;
  color: rgba(0, 0, 0, 0.6);
}
@media (min-width: 36em) {
  .td[data-label]::before {
    content: none;
  }
}

.car-name {
  align-items: center;
  padding: 4px;
  font-size: 8px;
}
@media (min-width: 36em) {
  .car-name {
    display: table-cell;
    padding: 4px 8px;
  }
}

.car-name > img {
  object-fit: contain;

  margin-right: 0px;
}
@media (min-width: 36em) {
  .car-name > img {
    display: block;
    margin-right: 0;
  }
}

.container {
  max-width: 45rem;
  margin: 0 8px;
  padding-bottom: 100vh;
}
@media (min-width: 36em) {
  .container {
    margin: auto;
  }
}

.container-table-reporte {
  width: 100%;
}

/* Estilos para el contenedor de búsqueda */
.search-container {
  position: relative;
  width: 50%;
  /* Otros estilos que desees aplicar */
}

/* Estilos para el wrapper que contiene el input y el ícono */
.search-wrapper {
  position: relative;
  display: flex;
  width: 70%;
  justify-content: flex-start;
  /* Ajusta el espaciado según tu diseño */
}

/* Estilos para el input */
.input-search {
  padding: 5px;
  padding-right: 50px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  box-shadow: 0 0 4px 0 #ccc;
  color: #393838;
  width: 100%;
  max-width: 600px; /* Define un ancho máximo */
  margin: 0 auto; /* Centra el input si el ancho es menor que el máximo */
}
.input-search::placeholder {
  color: #ccc; /* Color del placeholder */
  font-size: 14px;
  /* Otros estilos que desees aplicar al placeholder */
}

/* Estilos para el ícono */
.search-icon {
  position: absolute;
  top: 50%;
  right: 5px; /* Coloca el ícono a la derecha */
  transform: translateY(-50%);
  color: #ccc9c9; /* Color del ícono */
  /* Otros estilos que desees aplicar */
}

.top-button-table-consulta {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* Estilos para el elemento a la izquierda */
.left-element {
  /* Estilos específicos para el elemento de la izquierda */
  margin-right: auto; /* Empuja este elemento a la izquierda */
  margin-bottom: 5px;
}

/* Estilos para el elemento a la derecha */
.right-element {
  /* Estilos específicos para el elemento de la derecha */
  margin-left: auto; /* Empuja este elemento a la derecha */
  margin-bottom: 5px;
}

.button-styles-add-element {
  background-color: #41b6e6;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  border-width: 0;
  box-shadow: rgba(25, 25, 25, 0.04) 0 0 1px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
  transition: all 200ms;
  font-size: 16px;
  font-family: "Roboto Slab";
  border-radius: 5px;
  padding: 10px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.button-styles-add-element-search {
  background-color: #2ecc71;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  border-width: 0;
  box-shadow: rgba(25, 25, 25, 0.04) 0 0 1px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
  transition: all 200ms;
  font-size: 16px;
  font-family: "Roboto Slab";
  border-radius: 5px;
  padding: 10px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.button-styles-add-element-tipo {
  background-color: #41b6e6;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  border-width: 0;
  box-shadow: rgba(25, 25, 25, 0.04) 0 0 1px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
  transition: all 200ms;
  font-size: 12px;
  font-family: "Roboto Slab";
  border-radius: 5px;
  padding: 10px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

/*File selected*/

.file-selectadd {
  position: relative;
}

.file-selectadd::before {
  background-color: #41b6e6;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 3px;
  content: "Seleccionar"; /* testo por defecto */
  position: absolute;
  font-family: "Roboto Slab";
  padding: 5px;
  width: 100%;
  font-size: 12px;
}

.file-selectadd input[type="file"] {
  opacity: 0;
  width: 120px;
  height: 30px;
  font-family: "Roboto Slab";
  justify-content: center;
}

#src-fileadd::before {
  content: "Selecionar Imagen";
}

.custom-file-upload {
  border: 1px solid #ccc;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #41b6e6;
  color: #333;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.custom-file-upload:hover {
  background-color: #333;
  color: #fff;
}
.container-button-image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-error {
  font-size: 14px;
  color: #fff;
}

.input-content-img {
  width: 50%;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.row-selected-banner {
  display: flex;
  flex-direction: row;
}
.item-selected-marca {
  margin-right: 5px;
}
.item-selected-rol {
  margin-left: 5px;
}

/* TuComponente.css */
.tbody-estilos {
  background-color: #fff;
  position: sticky;
  top: 0;
  padding: 6px 10px;

  /* Otros estilos */
}

.tbody-estilos tr:nth-child(even) {
  background-color: #fff;
  /* Estilos para filas pares */
}

.tr-stylo-divider-catalogo-table {
  border-bottom: 1px solid #ccc;
}

.container-row-horarios-sucursal {
  display: flex;
  flex-direction: row;
}

.text-context-inputs-horario {
  margin-bottom: 2px;
  border: none;
  font-family: "Roboto Slab";
  font-size: 16px;
  font-weight: bold;
  color: #0b3a5a;
  display: flex;
  justify-content: center;
}
.text-context-inputs-horario-title {
  margin-bottom: 2px;
  border: none;
  font-family: "Roboto Slab";
  font-size: 12px;
  font-weight: bold;
  color: #0b3a5a;
  display: flex;
  justify-content: center;
}
.text-context-inputs-time {
  margin-bottom: 2px;
  border: none;
  font-family: "Roboto Slab";
  font-size: 12px;
  font-weight: 400;
  color: #0b3a5a;
}
.input-content-utc {
  width: 50%;
  margin-left: 10px;
}

.row-horario-list-sucursal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.container-list-horario-sucursal {
  display: flex;
  font-size: 10px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 2px;
}
.dia-list-horario-title {
  font-size: 10px;
  font-weight: bold;
  color: #0b3a5a;
}
.item-row-list-horario-sucursal {
  font-size: 10px;
  font-weight: 500;
  margin-right: 5px;
}

.header-off-table-search-elemnet {
  display: "flex";
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .header-off-table-search-elemnet {
    display: "flex";
    align-items: center;
    flex-direction: row;
  }
}
