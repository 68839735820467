.title-name-comunicados {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  color: #85929e;
  font-size: calc(10px + 2vmin);
  padding-bottom: 10px;
  font-family: "Roboto Slab";
  padding-top: 10px;
  border-bottom: 1px solid #bfc9ca;
}
.container-item-comunicados {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin: 30px;
  padding: 10px;
  color: #0b3a5a;
  font-family: "Roboto Slab";
  border: 1px #85929e;
}

.body-color {
  display: flex;
  flex-direction: column;
  background-color: #e5e8e8;
  width: 100%;
  height: 100%;
}
.icon-goback-title-comunicados {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
}
