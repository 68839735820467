.App-header {
  background-color: #0b3a5a;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding-left: 20px;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}
.image {
  margin: 1px;
}
.image-logo {
  width: 80px;
  height: 40px;
}
.content-header-buttons {
  display: flex;
  justify-content: space-between;
  width: 20%;
}
.button-top-header {
  padding: 5px 5px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  background-color: #0b3a5a;
  border: 1px solid #282c34;
}
.App-header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  margin: 0px;
}
.App-header-date {
  background-color: #41b6e6;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  width: "20%";
  height: "8%";
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Roboto Slab";
}
.App-logout-button {
  background-color: #0b3a5a;
  border-radius: 10px;
  border-width: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  margin-left: 10px;
  margin-right: 10px;
  transition: all 200ms;
}

.App-header-last {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: "Roboto Slab";
}
.Aling-date-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline: 5px;
}

@media only screen and (max-width: 600px) {
  /* For phone: */
  .App-header {
    background-color: #0b3a5a;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    color: white;
    padding-left: 5px;
    padding-right: 5px;
    box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
      0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
    width: 100%;
  }

  .image {
    width: 25%;
    height: 25%;
  }
  .content-header-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .button-top-header {
    padding: 5px 5px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: #fff;
    background-color: #0b3a5a;
    border: 1px solid #282c34;
  }
  .App-header-date {
    background-color: #41b6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    width: 80%;
    height: 100%;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-family: "Roboto Slab";
  }
  .App-header-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: white;
    margin: 0px;
    width: 100%;
  }
}
