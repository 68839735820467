.enlace-url {
  text-decoration: underline; /* Subrayado para indicar que es un enlace */
  cursor: pointer; /* Cambia el cursor al pasar por encima para indicar que es interactivo */
}

.enlace-url:hover {
  color: darkblue; /* Cambia el color al pasar por encima */
}

.carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  color: #fff;
  background-color: #0b3a5a;
  font-family: "Roboto Slab";
}
.carrucel-row {
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  color: #fff;
  background-color: #0b3a5a;
  font-family: "Roboto Slab";
}
.image-carrucel {
  display: flex;
  border-radius: 20px;
  max-width: 50%;
}
.title-banner-home {
  font-family: "Roboto Slab";
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}
.description-banner-home {
  font-family: "Roboto Slab";
  color: #fff;
  font-size: 20px;
}
.text-carrucel {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Roboto Slab";
  width: 50%;
}

@media only screen and (max-width: 800px) {
  .carrucel-row {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    color: #fff;
    background-color: #0b3a5a;
    font-family: "Roboto Slab";
  }
  .image-carrucel {
    display: flex;
    border-radius: 10px;
    max-width: 100%;
  }
  .title-banner-home {
    font-family: "Roboto Slab";
    color: #fff;
    font-size: 20px;
    font-weight: bold;
  }
  .description-banner-home {
    font-family: "Roboto Slab";
    color: #fff;
    font-size: 16px;
  }
  .text-carrucel {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-family: "Roboto Slab";
  }
}
@media only screen and (max-width: 650px) {
  .carrucel-row {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    color: #fff;
    background-color: #0b3a5a;
    font-family: "Roboto Slab";
  }
  .title-banner-home {
    font-family: "Roboto Slab";
    color: #fff;
    font-size: 16px;
    font-weight: bold;
  }
  .description-banner-home {
    font-family: "Roboto Slab";
    color: #fff;
    font-size: 12px;
  }
  .text-carrucel {
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 14px;
    font-family: "Roboto Slab";
  }
  .image-carrucel {
    display: flex;
    border-radius: 10px;
    max-width: 100%;
  }
}