.welcone-name {
  background-color: #0b3a5a;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  color: white;
  font-size: calc(10px + 2vmin);
  padding-bottom: 10px;
  font-family: "Roboto Slab";
}
.custom-button {
  background-color: transparent; /* Fondo transparente */
  color: initial; /* Restablece el color del texto */
  border: none; /* Elimina el borde del botón */
  padding: 0; /* Elimina el espaciado interno */
  cursor: pointer; /* Cambia el cursor al pasar el ratón por encima (opcional) */
}
.button-etiqueta {
  cursor: pointer;
}
.body-home-background {
  background-color: #0b3a5a;
}
.body-home {
  background-color: #0b3a5a;
  width: 100%;
  margin: auto;
  height: 100%;
  padding: 10px;
}
.text-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Roboto Slab";
  font-size: 12px;
  color: #0b3a5a;
}
.container-body {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 5px;
  width: 100%;
  height: 100%;
}

.image-opacity {
  opacity: 0.5;
}
.box {
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 5px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}
.carrucel-content {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e5e8e8;
}
.content-body {
  background-color: #0b3a5a;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon-box {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.icon-item-box {
  width: 5%;
  margin: 10px;
}
.img-home-icons {
  width: 80px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.img {
  width: 100%;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.img-disable {
  width: 100%;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  opacity: 0.4;
}

a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}

@media screen and (max-width: 1450px) {
  .container-body {
    width: 90%;
  }
}
@media screen and (max-width: 1300px) {
  .container-body {
    width: 95%;
  }
}
@media screen and (max-width: 1230px) {
  .icon-item-box {
    width: 10%;
  }
  .container {
    width: 100%;
  }
}
@media screen and (max-width: 1080px) {
  .icon-item-box {
    width: 15%;
  }
}
@media screen and (max-width: 860px) {
  .container-body {
    width: 80%;
  }
  .icon-item-box {
    width: 30%;
  }
}
@media screen and (max-width: 636px) {
  .title {
    font-size: 4rem;
    padding: 5px;
  }
}
@media screen and (max-width: 534px) {
  .text-button {
    font-size: 20px;
  }
  .container-body {
    width: 90%;
  }
}
@media screen and (max-width: 444px) {
  .container-body {
    width: 95%;
  }
  .title {
    padding: 4px;
  }
}
@media screen and (max-width: 416px) {
  .container-body {
    width: 100%;
  }
  .title {
    padding: 3px;
  }
}
@media screen and (max-width: 392px) {
  .title {
    padding: 3rem;
    font-size: 3rem;
  }
}
@media screen and (max-width: 378px) {
  .text {
    font-size: 15px;
  }
}
