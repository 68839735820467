.container-politicas {
  padding: 20px;
}
.box-info-politicas {
  margin: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: justify;
  padding: 30px;
}
.title-politicas {
  margin-top: 10px;
}
