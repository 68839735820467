.title-name-row {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  color: #85929e;
  font-size: calc(10px + 2vmin);
  padding-bottom: 5px;
  font-family: "Roboto Slab";
  padding-top: 10px;
  border-bottom: 1px solid #bfc9ca;
}
.title-top-table-indicadores-real {
  font-family: "Roboto Slab";
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  width: 10%;
}
.container-table-sale {
  border-radius: 10px;
  font-family: "Roboto Slab";
  margin: 10px;
}
.text-presupues-sale {
  color: #41b6e6;
  font-size: 16px;
}
.title-top-table-indicadores {
  font-family: "Roboto Slab";
  font-size: 16px;
  font-weight: bold;
  width: 50%;
  text-align: center;
  padding: 10px;
  width: 30%;
}
.title-name-indicadores {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #85929e;
  font-size: calc(10px + 2vmin);
  font-family: "Roboto Slab";
  width: 90%;
}

.body-color {
  display: flex;
  flex-direction: column;
  background-color: #e5e8e8;
  width: 100%;
  height: 100%;
  font-size: calc(8px + 2vmin);
}
.conter-day-table-indicadores {
  display: flex;
  flex-direction: row;
  width: 40%;
  margin-right: 65px;
  justify-content: space-around;
}
.image-icon {
  display: flex;
  justify-content: center;
  width: 25px;
  height: 25px;
}
.day-style {
  display: flex;
  font-family: "Roboto Slab";
  color: #0b3a5a;
  margin-top: 10px;
  margin-left: 20px;
  font-weight: bold;
}

/* indicators component*/
.container-indicators {
  display: flex;
  width: 90%;
  flex-direction: column;
  margin: auto;
  align-items: center;
  align-content: center;
}
.container-table {
  margin: 10px;
  display: flex;
  flex-direction: row;
}
.conetent-company-date-row {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.row-table-left {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.row-table-right {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.text-vs {
  font-family: "Roboto Slab";
  font-weight: bold;
  font-size: 14px;
  color: #0b3a5a;
}
.text-porcent {
  color: #41b6e6;
  font-weight: bold;
  font-size: 24px;
}
.text-presupues {
  color: #41b6e6;
  font-size: 16px;
}
.text-tota-real-price {
  color: #fff;
  font-weight: bold;
  font-size: 28px;
  font-family: "Roboto Slab";
  text-align: end;
  width: 50%;
}
.text-tota-real {
  color: #0b3a5a;
  font-weight: bold;
  font-size: 28px;
  font-family: "Roboto Slab";
  text-align: end;
  width: 30%;
}
.text-company {
  color: #0b3a5a;
  font-weight: bold;
  font-size: 14px;
  font-family: "Roboto Slab";
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 22px;
  margin-top: 10px;
  width: 50%;
}
.text-company-date {
  color: #0b3a5a;
  font-weight: bold;
  font-size: 14px;
  font-family: "Roboto Slab";
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-right: 22px;
  margin-top: 10px;
  width: 50%;
}
.button-goback-style {
  background-color: #fff;
  border: none;
  cursor: pointer;
  color: #85929e;
}

.icon-goback-title {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
}
.buttoon-selected-indicadores {
  background-color: #fff;
  border: none;
  color: #85929e;
  cursor: pointer;
  font-family: "Roboto Slab";
  font-size: 18px;
  font-weight: bold;
}
.buttoon-selected-indicadores-active {
  background-color: #85929e;
  border: none;
  color: #fff;
  cursor: pointer;
  font-family: "Roboto Slab";
  font-size: 18px;
  font-weight: bold;
}
/* Table Sales right*/

.IconImage {
  width: 30%;
  height: 30%;
}
.container-table-sale-right {
  background-color: #eaeff3;
  border-radius: 10px;
  font-family: "Roboto Slab";
  margin: 10px;
}
.box-top-table-sale-right {
  background-color: #d5dbdb;
  color: #0b3a5a;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 50px;
  align-items: center;
  align-content: center;
  text-align: center;
  width: 100%;
}
.box-sale-icon-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 40%;
  padding: 10px;
}
.box-body-table-sale-right {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.item-box-table-sales1-right {
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-right: 2px solid #eaeff3;
}
.item-box-table-sales-right {
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 1px;
  padding: 10px;
}
.item-box-table-sales3-right {
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 1px;
  padding: 10px;
  border-bottom-right-radius: 10px;
}

/* Table Sales left*/

.box-top-table-sale {
  background-color: #41b6e6;
  color: #fff;
  display: flex;
  flex-direction: row;
  padding-left: 5px;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 50px;
  align-items: center;
  align-content: center;
  width: 100%;
  text-align: center;
}
.box-sale-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 40%;
  padding: 10px;
}
.box-body-table-sale {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.item-box-table-sales1 {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 200px;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-left: #41b6e6;
  border-right: 1px solid #41b6e6;
}
.item-box-table-sales {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 200px;
  padding: 10px;
  border-left: #41b6e6;
  border-right: 1px solid #41b6e6;
}
.item-box-table-sales3 {
  background-color: #fff;
  display: flex;
  transition: all 200ms;
  flex-direction: column;
  width: 100%;
  height: 200px;
  padding: 10px;
  border-bottom-right-radius: 10px;
  display: inline-block;
  font-family: "Roboto Slab";
}

@media only screen and (max-width: 1020px) {
  .conter-day-table-indicadores {
    display: flex;
    flex-direction: row;
    width: 70%;
    margin-right: 65px;
    justify-content: space-around;
  }
  .buttoon-selected-indicadores {
    background-color: #fff;
    border: none;
    color: #85929e;
    cursor: pointer;
    font-family: "Roboto Slab";
    font-size: 16px;
    font-weight: bold;
  }
  .buttoon-selected-indicadores-active {
    background-color: #85929e;
    border: none;
    color: #fff;
    cursor: pointer;
    font-family: "Roboto Slab";
    font-size: 16px;
    font-weight: bold;
  }
  .text-porcent {
    color: #41b6e6;
    font-weight: bold;
    font-size: 22px;
  }
  .text-tota-real-price {
    color: #fff;
    font-weight: bold;
    font-size: 22px;
    font-family: "Roboto Slab";
    text-align: end;
    width: 30%;
  }
  .title-top-table-indicadores {
    font-family: "Roboto Slab";
    font-size: 14px;
    font-weight: bold;
    width: 50%;
    text-align: center;
    padding: 10px;
    width: 30%;
  }

  .text-tota-real {
    color: #0b3a5a;
    font-weight: bold;
    font-size: 22px;
    font-family: "Roboto Slab";
    text-align: end;
    width: 30%;
  }
}
@media only screen and (max-width: 860px) {
  .buttoon-selected-indicadores {
    background-color: #fff;
    border: none;
    color: #85929e;
    cursor: pointer;
    font-family: "Roboto Slab";
    font-size: 14px;
    font-weight: bold;
  }
  .buttoon-selected-indicadores-active {
    background-color: #85929e;
    border: none;
    color: #fff;
    cursor: pointer;
    font-family: "Roboto Slab";
    font-size: 14px;
    font-weight: bold;
  }
  .text-porcent {
    color: #41b6e6;
    font-weight: bold;
    font-size: 18px;
  }
  .text-tota-real-price {
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    font-family: "Roboto Slab";
    text-align: end;
    width: 30%;
  }
  .title-top-table-indicadores {
    font-family: "Roboto Slab";
    font-size: 12px;
    font-weight: bold;
    width: 50%;
    text-align: center;
    padding: 10px;
    width: 30%;
  }
  .text-tota-real {
    color: #0b3a5a;
    font-weight: bold;
    font-size: 18px;
    font-family: "Roboto Slab";
    text-align: end;
    width: 30%;
  }
}

/*Resposnsive css*/
@media only screen and (max-width: 720px) {
  /* For destock: */
  .title-name-row {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    color: #85929e;
    font-size: calc(10px + 2vmin);
    font-family: "Roboto Slab";
    border-bottom: 1px solid #bfc9ca;
    width: 100%;
  }
  .title-name-indicadores {
    display: flex;
    background-color: #fff;
    color: #85929e;
    font-size: calc(10px + 2vmin);
    font-family: "Roboto Slab";
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    margin-left: 10px;
    margin-right: 10px;
  }
  .conter-day-table-indicadores {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: 8px;
    justify-content: space-around;
    margin-right: 20px;
    margin-left: 0px;
  }
  .container-table {
    margin: 10px;
    display: flex;
    flex-direction: column;
  }
  .item-box-table-sales1 {
    background-color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    width: 32.5%;
    height: 20%;
    padding: 20px;
    border-bottom-left-radius: 10px;
    border-left: #41b6e6;
  }
  .item-box-table-sales {
    background-color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    width: 32.5%;
    height: 20%;

    padding: 20px;
    border-left: #41b6e6;
  }
  .item-box-table-sales3 {
    background-color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    width: 32.5%;
    height: 20%;

    padding: 20px;
    border-bottom-right-radius: 10px;
  }
  .row-table-left {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .row-table-right {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .conetent-company-date-row {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .icon-goback-title {
    margin-left: -40px;
    display: flex;
    flex-direction: row;
  }
  .buttoon-selected-indicadores {
    background-color: #fff;
    border: none;
    color: #85929e;
    cursor: pointer;
    font-family: "Roboto Slab";
    font-size: 14px;
    font-weight: bold;
  }
  .buttoon-selected-indicadores-active {
    background-color: #85929e;
    border: none;
    color: #fff;
    cursor: pointer;
    font-family: "Roboto Slab";
    font-size: 14px;
    font-weight: bold;
  }
  .container-indicators {
    display: flex;
    width: 90%;
    flex-direction: column;
    margin: auto;
  }
  .container-table-sale {
    border-radius: 10px;
    font-family: "Roboto Slab";
    margin: 10px;
  }
  .title-top-table-indicadores {
    font-family: "Roboto Slab";
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    padding: 10px;
    width: 30%;
  }
  .title-top-table-indicadores-real {
    font-family: "Roboto Slab";
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    padding: 10px;
    width: 30%;
  }
  .text-tota-real-price {
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    font-family: "Roboto Slab";
    text-align: end;
    width: 30%;
  }
  .text-tota-real {
    color: #0b3a5a;
    font-weight: bold;
    font-size: 18px;
    font-family: "Roboto Slab";
    text-align: end;
    width: 30%;
  }
  .text-porcent {
    color: #41b6e6;
    font-weight: bold;
    font-size: 18px;
  }
  .box-sale-icon-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 40%;
    padding: 10px;
  }
  .box-body-table-sale {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .text-vs-sale {
    font-family: "Roboto Slab";
    font-weight: bold;
    font-size: 10px;
    color: #0b3a5a;
  }
  .text-presupues-sale {
    color: #41b6e6;
    font-size: 12px;
  }
  .box-sale-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 40%;
    padding: 10px;
  }
  .conetent-company-date-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .container-table-sale {
    border-radius: 10px;
    font-family: "Roboto Slab";
    margin: 10px;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
}
