.title-name-hub {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  color: #85929e;
  font-size: calc(10px + 2vmin);
  padding-bottom: 10px;
  font-family: "Roboto Slab";
  padding-top: 10px;
  border-bottom: 1px solid #bfc9ca;
}
.body-color {
  background-color: #e5e8e8;
}
.container-body-hub {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 10px;
  width: 100%;
  height: 100%;
  margin-top: 20px;
}

.box-hub {
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 5px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

.icon-box-hub {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.icon-item-box-hub {
  width: 10%;
  margin: 30px;
}
.img-hub {
  width: 100%;
}
.img-hub-disable {
  width: 100%;
  opacity: 0.4;
}

a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
.icon-goback-title-hub {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 1450px) {
  .container-body-hub {
    width: 90%;
    height: 100%;
  }
  .icon-goback-title {
    margin-left: 10px;
    display: flex;
    flex-direction: row;
  }
}
@media screen and (max-width: 1300px) {
  .container-body-hub {
    width: 90%;
    height: 100%;
  }
  .icon-goback-title {
    margin-left: 10px;
    display: flex;
    flex-direction: row;
  }
}
@media screen and (max-width: 1230px) {
  .icon-item-box-hub {
    width: 10%;
  }
  .container-body-hub {
    width: 90%;
    height: 100%;
  }
  .icon-goback-title {
    margin-left: 10px;
    display: flex;
    flex-direction: row;
  }
}
@media screen and (max-width: 1080px) {
  .icon-item-box-hub {
    width: 15%;
  }
}
@media screen and (max-width: 860px) {
  .container-body-hub {
    width: 90%;
    height: 100%;
  }
  .icon-item-box-hub {
    width: 30%;
  }
  .icon-goback-title {
    margin-left: 10px;
    display: flex;
    flex-direction: row;
  }
}
@media screen and (max-width: 636px) {
  .title {
    font-size: 4rem;
    padding: 5px;
  }
  .icon-goback-title {
    margin-left: 10px;
    display: flex;
    flex-direction: row;
  }
}
@media screen and (max-width: 534px) {
  .text-button {
    font-size: 20px;
  }
  .container-body-hub {
    width: 90%;
    height: 100%;
  }
}
@media screen and (max-width: 444px) {
  .container-body-hub {
    width: 90;
    height: 100%;
  }
  .title {
    padding: 4px;
  }
  .icon-goback-title {
    margin-left: 10px;
    display: flex;
    flex-direction: row;
  }
}
@media screen and (max-width: 416px) {
  .container-body-hub {
    width: 90%;
    height: 100%;
  }
  .title {
    padding: 3px;
  }
  .icon-goback-title {
    margin-left: 10px;
    display: flex;
    flex-direction: row;
  }
}
@media screen and (max-width: 392px) {
  .title {
    padding: 3rem;
    font-size: 3rem;
  }
}
@media screen and (max-width: 378px) {
  .text {
    font-size: 15px;
  }
  .icon-goback-title {
    margin-left: 10px;
    display: flex;
    flex-direction: row;
  }
}
