.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.logo {
  width: 20%;
  height: 20%;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #0b3a5a;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding-left: 20px;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}
.App-header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  margin: 0px;
}
.App-header-date {
  background-color: #41b6e6;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  width: "20%";
  height: "8%";
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Roboto Slab";
}
.App-logout-button {
  background-color: #0b3a5a;
  border-radius: 10px;
  border-width: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  margin-left: 10px;
  margin-right: 10px;
  transition: all 200ms;
}
.App-login-button {
  background-color: #41b6e6;
  border-radius: 10px;
  border-width: 0;
  box-shadow: rgba(25, 25, 25, 0.04) 0 0 1px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  height: 50px;
  width: 300px;
  padding: 0 35px;
  transition: all 200ms;
  font-size: calc(10px + 2vmin);
  font-family: "Roboto Slab";
}
.App-header-last {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: "Roboto Slab";
}
.App-body-login {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(40px + 2vmin);
  color: white;
  margin-bottom: "80%";
  font-family: "Roboto Slab";
  background-image: url("./assets/portada-web.jpg");
  height: "100%";
  width: "100%";
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 20px;
  height: 20px;
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
.image-opacity {
  opacity: 0.2;
}
body {
  background-color: #e5e8e8;
}
a:link,
a:visited {
  color: #fff;
  text-decoration: underline;
  cursor: auto;
}
.avisoprivacidad {
  font-size: 14px;
  margin-top: 30px;
}
@media screen and (max-width: 920px) {
  .App-body-login {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(40px + 2vmin);
    color: white;
    margin-bottom: "80%";
    font-family: "Roboto Slab";
    background-image: url("./assets/imageblueperson.png");
    height: "100%";
    width: "100%";
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
  }
  .logo {
    width: 55%;
    height: 55%;
    pointer-events: none;
  }
}
.icono-notificacion-view{
  display: flex;
  justify-content: center;
  align-items: center;
}