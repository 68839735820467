.form-reclasificacion {
  text-align: center;
}
input {
  text-align: start;
}
.conteiner-modal-body-evidencias {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  flex-direction: column;
}

.container-table-reclasificacion {
  background-color: #fff;
  display: flex;
  width: 80%;
  height: 100%;
  margin: auto;
  border-radius: 10px;
  flex-direction: column;
  margin-top: 20px;
  padding: 0px;
  margin-bottom: 20px;
}
.container-picker-reclasificacion {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 5px;
  margin-left: 10px;
  padding: 20px;
  background-color: #fff;
  width: 80%;
  height: 100%;
  margin: auto;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.text-picker {
  font-size: 14px;
  font-family: "Roboto Slab";
  color: #0b3a5a;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 10px;
}
.button-styles-reclasificacion {
  background-color: #41b6e6;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  border-width: 0;
  box-shadow: rgba(25, 25, 25, 0.04) 0 0 1px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
  transition: all 200ms;
  font-size: 16px;
  font-family: "Roboto Slab";
  border-radius: 5px;
  padding: 10px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30%;
}
.button-styles-reclasificacionCloseStore {
  background-color: #2ecc71;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  border-width: 0;
  box-shadow: rgba(25, 25, 25, 0.04) 0 0 1px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
  transition: all 200ms;
  font-size: 16px;
  font-family: "Roboto Slab";
  border-radius: 5px;
  padding: 10px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30%;
}
.item-row-reclasificacion {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  margin-left: 5px;
  padding: 20px;
  width: 30%;
}
.item-row-reclasificacion-separador {
  display: flex;
  flex-direction: column;
}
.text-item-reclasificacion {
  font-size: 12px;
  font-family: "Roboto Slab";
  color: #0b3a5a;
  width: 100%;
  height: 15px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
}
.input-item-reclasificacion {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #fff;
  border: 2px solid #d7dbdd;
  height: 30px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-row-reclasificacion-separador {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.text-item-reclasificacion-totales {
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-family: "Roboto Slab";
  color: #fff;
  width: 100%;
  height: 25px;
  font-weight: bold;
  background-color: #0b3a5a;
  padding: 5px;
  margin-bottom: 10px;
  border: none;
}
.text-item-reclasificacion-separador {
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-family: "Roboto Slab";
  color: #fff;
  width: 100%;
  height: 30px;
  font-weight: bold;
  background-color: #41b6e6;
  padding: 5px;
  margin-bottom: 10px;
  border: none;
}
.conteiner-totales-reclasificacion {
  width: 100%;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
}
.text-item-reclasificacion-totales-title {
  font-size: 12px;
  font-family: "Roboto Slab";
  color: #0b3a5a;
  width: 100%;
  height: 15px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
}
.container-button-reclasificacion {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin: 20px;
}
.row-content-reclasificacion-grit {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.inputs-content-reclasificacion {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.save-data-reclasificacion {
  background-color: #cbffc0;
  width: 100%;
  color: #0b3a5a;
  font-size: 16px;
  font-family: "Roboto Slab";
  border-radius: 5px;
  font-weight: bold;
  margin: 5px;
  padding: 5px;
}

.item-row-attract-files {
  flex-direction: row;
  display: flex;
  justify-content: space-around;
}
.botton-part-form-reclasificacion {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.botton-part-form-reclasificacion-item-row {
  display: flex;
  width: 50%;
  flex-direction: column;
  padding: 20px;
  align-content: center;
}
.input-name {
  margin: 5px;
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  border: 2px solid #d7dbdd;
  height: 40px;
  padding: 5px;
  display: flex;
  text-align: center;
  font-size: 12px;
}
.input-comentario {
  margin: 5px;
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  border: 2px solid #d7dbdd;
  padding: 5px;
  text-align: center;
  font-size: 12px;
  resize: none;
}
.input-comentario-error {
  border: 2px solid red;
  border-color: red;
}


.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-input__label {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #0b3a5a;
  background-color: #fff;
  border: none;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.text-top-image-modal {
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 16px;
  font-family: "Roboto Slab";
  font-weight: bold;
}
.button-icon-images {
  border: none;
  background-color: #fff;
  margin-bottom: 5px;
}
.file-input__label {
  width: 100%;
  height: 120px;
}
.button-row-evidencias {
  justify-content: center;
  width: 33%;
  font-size: 14px;
  font-family: "Roboto Slab";
  font-weight: bold;
  text-align: center;
}
.spam-text {
  font-size: 14px;
  font-family: "Roboto Slab";
  font-weight: bold;
  text-align: center;
  height: 40px;
}
/*Resposnsive css*/
@media only screen and (max-width: 760px) {
  .spam-text {
    font-size: 10px;
    font-family: "Roboto Slab";
    font-weight: bold;
    text-align: center;
  }
  .button-row-evidencias {
    justify-content: center;
    width: 33%;
    font-size: 10px;
    font-family: "Roboto Slab";
    font-weight: bold;
    text-align: center;
  }
  .button-icon-images {
    border: none;
    background-color: #fff;
    margin-bottom: 5px;
  }
  .icon-reclasificacin {
    width: 40px;
    height: 40px;
  }
  .file-input__label {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 600;
    color: #0b3a5a;
    padding: 10px 12px;
    background-color: #fff;
    border: none;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
  }
  .customModalPickImage {
    max-width: 100%;
    width: 100%;
    height: 500px;
  }
  .container-button-reclasificacion {
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .button-styles-reclasificacion {
    background-color: #41b6e6;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    border-width: 0;
    box-shadow: rgba(25, 25, 25, 0.04) 0 0 1px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
    transition: all 200ms;
    font-size: 16px;
    font-family: "Roboto Slab";
    border-radius: 5px;
    padding: 10px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
  }
  .button-styles-reclasificacionCloseStore {
    background-color: #2ecc71;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    border-width: 0;
    box-shadow: rgba(25, 25, 25, 0.04) 0 0 1px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
    transition: all 200ms;
    font-size: 16px;
    font-family: "Roboto Slab";
    border-radius: 5px;
    padding: 10px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
  }

  .botton-part-form-reclasificacion-item-row {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 20px;
    align-content: center;
  }
  .botton-part-form-reclasificacion {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .item-row-reclasificacion {
    display: flex;
    flex-direction: column;
    margin-right: 5px;
    margin-left: 5px;
    padding: 20px;
    width: 100%;
  }
  .content-row-reclasificacion-separador {
    display: flex;
    justify-content: flex-start;
  }
  .row-content-reclasificacion-grit {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .conteiner-totales-reclasificacion {
    width: 100%;
    display: flex;
    border-radius: 5px;
    flex-direction: column;
  }
  .button-styles-reclasificacion {
    background-color: #41b6e6;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    border-width: 0;
    box-shadow: rgba(25, 25, 25, 0.04) 0 0 1px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
    transition: all 200ms;
    font-size: 16px;
    font-family: "Roboto Slab";
    border-radius: 5px;
    padding: 10px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;
  }
  .button-styles-reclasificacionCloseStore {
    background-color: #2ecc71;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    border-width: 0;
    box-shadow: rgba(25, 25, 25, 0.04) 0 0 1px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
    transition: all 200ms;
    font-size: 16px;
    font-family: "Roboto Slab";
    border-radius: 5px;
    padding: 10px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;
  }
}

.icon-reclasificacin {
  width: 50%;
  height: 50%;
}
.customModalPickImage {
  max-width: 200px;
  width: 30%;
  height: 500px;
}
.image-pick-recolecion {
  width: 50%;
  height: 50%;
  justify-content: center;
  align-items: center;
}
.pick-image-button-reclasificacion {
  border: none;
  background-color: #fff;
}

/*File selected*/

.file-select {
  position: relative;
}

.file-select::before {
  background-color: #fff;
  color: red;
  border-radius: 5px;
  font-family: "Roboto Slab";
  border: 2px solid red;
  width: 80px;
  height: 40px;
  cursor: pointer;
  margin-top: 0px;
}
.file-button-save {
  background-color: #fff;
  color: green;
  border-radius: 5px;
  font-family: "Roboto Slab";
  border: 2px solid green;
  width: 80px;
  height: 40px;
  cursor: pointer;
  margin: 5px;
}

.file-select input[type="file"] {
  opacity: 0;
  width: 80px;
  height: 40px;
  font-family: "Roboto Slab";
  justify-content: center;
}

#src-file1::before {
  content: "Cancelar";
}

.custom-file-upload {
  border: 1px solid #ccc;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #f5f5f5;
  color: #333;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.custom-file-upload:hover {
  background-color: #333;
  color: #fff;
}
.container-button-image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-error {
  font-size: 14px;
  color: red;
}
.text-error-evidencia {
  font-size: 10px;
  color: red;
}
.file-button-cancel {
  background-color: #fff;
  color: red;
  border-radius: 5px;
  font-family: "Roboto Slab";
  border: 2px solid red;
  width: 80px;
  height: 40px;
  cursor: pointer;
  margin: 5px;
  }
  
  .file-button-disable {
  background-color: #899194;
  color: #fff;
  border-radius: 5px;
  border: 2px solid #899194;
  width: 90px;
  height: 40px;
  cursor: pointer;
  margin: 5px;
  font-family: "Roboto Slab";
  }

  .error-input-data{
    font-size: 10px;
    color: #a0081c;
    font-family: "Roboto Slab ";
    font-weight: bold;
  }